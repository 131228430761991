import Vue from "vue";
import Components from "./components";
import "./app.css";
import 'lazysizes';
import AOS from 'aos';
AOS.init();

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});
